<script lang="ts">
	import { createField } from 'felte';

	export let extraClasses = '';
	export let size: 'small' | 'medium' = 'medium';
	export let value = '';
	export let hasError = false;
	export let name = '';
	export let disabled = false;

	const { field } = createField(name);

	function getSize() {
		switch (size) {
			case 'small':
				return 'py-3 px-8';
			case 'medium':
			default:
				return 'p-[16px]';
		}
	}
</script>

<input
	use:field
	bind:value
	{disabled}
	class:border-red={hasError}
	class={`box-border w-full rounded-xl border border-solid border-border bg-midgrey ${getSize()}
      text-caption7 text-white placeholder:font-primary
      placeholder:text-placeholder focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50
      ${extraClasses}`}
	{...$$restProps}
	on:blur
	on:focus
	on:click
	on:input
	on:keypress />

<style>
	/* For Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* For Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
</style>
